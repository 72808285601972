import Constantes from '@constantes/pesquisa/pontos';

const { REDUCER_TYPE } = Constantes;

const INITIAL_STATE = {
    parametrosPesquisa: {
        cnes: '',
        instituica: '',
        uf: null,
        cidade: null,
        setor: '',
        nome: '',
        tipo: null,
        situacao: null,
        rede: null,
        'data-de': null,
        'data-ate': null
    }
};

function pesquisaPontosReducer(state, action) {
    if (!state) {
        state = INITIAL_STATE;
    }

    const { type, payload } = action;

    if (type === REDUCER_TYPE.LIMPAR_PARAMETROS_PESQUISA) {
        return {
            ...state,
            parametrosPesquisa: INITIAL_STATE.parametrosPesquisa
        };
    }

    if (type === REDUCER_TYPE.SETAR_PARAMETROS_PESQUISA) {
        return {
            ...state,
            parametrosPesquisa: payload.parametros
        };
    }
    return state;
}

export default pesquisaPontosReducer;