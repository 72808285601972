import React, { useRef, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { Formik, Field, FastField } from 'formik';
import { estadoInicial } from './estadoInicial';
import { getHeaders } from '@src/request';
import axios from 'axios';
import { makeStyles } from "@material-ui/core/styles";
import PropTypes from 'prop-types';
import translate from '@componentes/translate';
import moment from 'moment';
import {
    setParametrosPesquisa as setParametrosPesquisaAction,
    limparParametrosPesquisa as limparParametrosPesquisaAction
} from '@redux/actions/pesquisa/pontos';
import {
    SttGrid,
    SttButton,
    SttInput,
    SttNumberInput,
    SttAutocomplete,
    SttDatePicker
} from '@stt-componentes/core';
import {
    CNES,
    UF,
    CIDADE,
    INSTITUICAO,
    NOME,
    SETOR,
    SITUACAO,
    TIPO,
    DATA_DE,
    DATA_ATE,
    REDE
} from './fieldNames';
import validationSchema from './validationSchema';
import { PONTOS } from '@common/Constants';
import { temVinculoEstadual, pegarEstadosVinculoPerfil } from '@src/security/acl';

const useStyles = makeStyles(theme => ({
    tableWrapper: {
        marginTop: theme.spacing(3)
    }
}));

const FormPesquisa = (props) => {
    const {
        setParametrosPesquisa,
        limparParametrosPesquisa,
        parametrosPesquisa,
        callbackAndamento,
        callbackLimpar,
        page,
        count,
        orderBy,
        order,
        callbackBusca,
        buscar,
        user,
        strings
    } = props;

    const classes = useStyles();
    const ADM_API_BASE_URL = global.gConfig.url_base_administrativo;
    const UTILITARIOS_API_BASE_URL = global.gConfig.url_base_utilitarios;
    const formRef = useRef();
    const schema = validationSchema(strings);

    const [estados, setEstados] = useState([]);
    const [cidades, setCidades] = useState([]);
    const [redes, setRedes] = useState([]);

    useEffect(() => {
        if (buscar && formRef.current) {
            formRef.current.handleSubmit();
        }
    }, [buscar]);

    useEffect(() => {
        let complementoUrlEstado = '';
        if (temVinculoEstadual(user)) {
            let estados = pegarEstadosVinculoPerfil(user);
            complementoUrlEstado = `?ids=${estados.join(',')}`;
        }

        axios.get(`${global.gConfig.url_base_utilitarios}/localizacao/estado${complementoUrlEstado}`, { headers: getHeaders() })
            .then((response) => {
                const { data } = response;
                setEstados(data.data.itens);
            })
            .catch(err => console.log(err));

        axios.get(`${UTILITARIOS_API_BASE_URL}/rede`, { headers: getHeaders() })
            .then((response) => {
                const { data } = response;
                setRedes(data.data.itens);
            })
            .catch(err => {
                setRedes([])
            });
    }, []);

    const baixarListaCidades = async (uf) => {
        if (uf) {
            axios.get(`${global.gConfig.url_base_utilitarios}/localizacao/cidade?id-estado=${uf.id}`)
                .then((response) => {
                    const { data } = response;
                    setCidades(data.data.itens);
                })
                .catch(err => {
                    setCidades([]);
                    console.log(err)
                });
        } else {
            setCidades([])
        }
    }

    const estruturarFiltros = (dados) => {
        const filtros = {};
        const offset = (page * count);

        filtros.count = count;
        filtros.start = offset;
        filtros.sort = orderBy;
        filtros.direction = order;

        if (dados['data-de']) {
            filtros['data-de'] = moment(dados['data-de']).format('YYYY-MM-DD');
        }

        if (dados['data-ate']) {
            filtros['data-ate'] = moment(dados['data-ate']).format('YYYY-MM-DD');
        }

        if (dados.nome) {
            filtros.nome = dados.nome.trim();
        }

        if (dados.instituicao) {
            filtros.instituicao = dados.instituicao.trim();
        }

        if (dados.setor) {
            filtros.setor = dados.setor.trim();
        }

        if (dados.tipo) {
            filtros.tipo = dados.tipo.identificador;
        }

        if (dados.situacao) {
            filtros.situacao = dados.situacao.identificador;
        }

        if (dados.cnes) {
            filtros.cnes = dados.cnes;
        }

        if (dados.uf) {
            filtros.uf = dados.uf.id;
        }

        if (dados.cidade) {
            filtros.cidade = dados.cidade.id;
        }

        if (dados.rede) {
            filtros.rede = dados.rede.id;
        }

        setParametrosPesquisa(dados);
        return filtros;
    }

    return (
        <Formik
            innerRef={formRef}
            initialValues={parametrosPesquisa}
            validationSchema={schema}
            onSubmit={(data, { setSubmitting }) => {
                let params = estruturarFiltros(data);
                callbackAndamento(true);
                axios.get(`${ADM_API_BASE_URL}/ponto`, { params: params, headers: getHeaders() })
                    .then((response) => {
                        const dados = response.data.data;
                        callbackBusca(dados);
                    })
                    .catch(err => {
                        callbackBusca({
                            totalRegistros: 0,
                            itens: []
                        });
                    })
                    .finally(() => {
                        callbackAndamento(false);
                        setSubmitting(false);
                    });
            }}
        >
            {
                ({
                    isSubmitting,
                    handleSubmit,
                    resetForm,
                    isValid
                }) => {
                    return (
                        <form onSubmit={handleSubmit} noValidate onKeyDown={(e) => {
                            if (e.key === 'Enter') {
                                e.preventDefault();
                                handleSubmit();
                            }
                        }}>
                            <SttGrid container spacing={1}>
                                <SttGrid item xs={12} sm={12} md={6} lg={1}>
                                    <FastField name={DATA_DE}>
                                        {({
                                            field: { name, value, },
                                            form: { setFieldValue, setFieldError, setFieldTouched },
                                            meta
                                        }) => (
                                            <SttDatePicker
                                                label={strings.dataCriacaoDe}
                                                inputprops={{
                                                    name: name
                                                }}
                                                disableFuture
                                                maxDate={new Date()}
                                                error={meta.touched && meta.error ? true : false}
                                                onError={() => {
                                                    setFieldError(DATA_DE, meta.error);
                                                }}
                                                value={value}
                                                onBlur={() => {
                                                    setFieldTouched(DATA_DE, true);
                                                }}
                                                helperText={meta.touched && meta.error ? meta.error : undefined}
                                                onChange={date => setFieldValue(DATA_DE, date, true)}
                                                onClose={() => setFieldTouched(DATA_DE, true)}
                                            />
                                        )
                                        }
                                    </FastField>
                                </SttGrid>
                                <SttGrid item xs={12} sm={12} md={6} lg={1}>
                                    <FastField name={DATA_ATE}>
                                        {({
                                            field: { name, value, },
                                            form: { setFieldValue, setFieldError, setFieldTouched },
                                            meta
                                        }) => (
                                            <SttDatePicker
                                                label={strings.dataCriacaoAte}
                                                inputprops={{
                                                    name: name
                                                }}
                                                disableFuture
                                                maxDate={new Date()}
                                                error={meta.touched && meta.error ? true : false}
                                                onError={() => {
                                                    setFieldError(DATA_ATE, meta.error);

                                                }}
                                                value={value}
                                                onBlur={() => {
                                                    setFieldTouched(DATA_ATE, true);
                                                }}
                                                helperText={meta.touched && meta.error ? meta.error : undefined}
                                                onChange={date => setFieldValue(DATA_ATE, date, true)}
                                                onClose={() => setFieldTouched(DATA_ATE, true)}
                                            />
                                        )
                                        }
                                    </FastField>
                                </SttGrid>
                                <SttGrid item xs={12} sm={6} md={2} lg={1}>
                                    <FastField name={CNES}>
                                        {({
                                            field,
                                            meta
                                        }) => (
                                            <SttNumberInput
                                                inputProps={{
                                                    maxLength: 7
                                                }}
                                                {...field}
                                                label={strings.cnes}
                                                error={meta.touched && meta.error ? true : false}
                                                helperText={meta.touched && meta.error ? meta.error : undefined}
                                            />
                                        )}
                                    </FastField>
                                </SttGrid>
                                <SttGrid item xs={12} sm={6} md={4} lg={3}>
                                    <FastField name={INSTITUICAO}>
                                        {({
                                            field,
                                            meta
                                        }) => (
                                            <SttInput
                                                {...field}
                                                label={strings.instituicao}
                                                inputProps={{ style: { textTransform: "uppercase" } }}
                                                value={field.value}
                                                error={meta.touched && meta.error ? true : false}
                                                helperText={meta.touched && meta.error ? meta.error : undefined}
                                            />
                                        )}
                                    </FastField>
                                </SttGrid>
                                <SttGrid item xs={12} sm={6} md={2} lg={1}>
                                    <Field name={UF}>
                                        {({
                                            field: { name, value, onBlur },
                                            form: { setFieldValue },
                                        }) => (
                                            <SttAutocomplete
                                                inputprops={{
                                                    name: name,
                                                    label: strings.uf
                                                }}
                                                options={estados}
                                                value={value}
                                                getOptionLabel={option => option?.sigla || ''}
                                                getOptionSelected={(option, valueOptionSelected) => option?.id === valueOptionSelected?.id}
                                                onBlur={onBlur}
                                                onChange={(e, item) => {
                                                    setFieldValue(UF, item || null);
                                                    setFieldValue(CIDADE, null);
                                                    baixarListaCidades(item);
                                                }} />
                                        )}
                                    </Field>
                                </SttGrid>
                                <SttGrid item xs={12} sm={6} md={4} lg={3}>
                                    <Field name={CIDADE}>
                                        {({
                                            field: { name, value, onBlur },
                                            form: { setFieldValue, values },
                                        }) => (
                                            <SttAutocomplete
                                                inputprops={{
                                                    name: name,
                                                    label: strings.municipio,
                                                }}
                                                options={cidades}
                                                value={value}
                                                disabled={!values.uf}
                                                getOptionLabel={option => option?.nome || ''}
                                                getOptionSelected={(option, valueOptionSelected) => option?.id === valueOptionSelected?.id}
                                                onBlur={onBlur}
                                                onChange={(e, item) => setFieldValue(CIDADE, item || null)} />
                                        )}
                                    </Field>
                                </SttGrid>
                                <SttGrid item xs={12} sm={6} md={4} lg={2}>
                                    <FastField name={SETOR}>
                                        {({
                                            field,
                                            meta
                                        }) => (
                                            <SttInput
                                                {...field}
                                                label={strings.setor}
                                                inputProps={{ style: { textTransform: "uppercase" } }}
                                                value={field.value}
                                                error={meta.touched && meta.error ? true : false}
                                                helperText={meta.touched && meta.error ? meta.error : undefined}
                                            />
                                        )}
                                    </FastField>
                                </SttGrid>
                                <SttGrid item xs={12} sm={6} md={4} lg={3}>
                                    <FastField name={NOME}>
                                        {({
                                            field,
                                            meta
                                        }) => (
                                            <SttInput
                                                {...field}
                                                label={strings.nome}
                                                inputProps={{ style: { textTransform: "uppercase" } }}
                                                value={field.value}
                                                error={meta.touched && meta.error ? true : false}
                                                helperText={meta.touched && meta.error ? meta.error : undefined}
                                            />
                                        )}
                                    </FastField>
                                </SttGrid>
                                <SttGrid item xs={12} sm={6} md={2}>
                                    <Field name={TIPO}>
                                        {({
                                            field: { name, value, onBlur },
                                            form: { setFieldValue }
                                        }) => (
                                            <SttAutocomplete
                                                inputprops={{
                                                    name: name,
                                                    label: strings.tipo
                                                }}
                                                getOptionLabel={option => option?.descricao || ''}
                                                getOptionSelected={(option, valueOptionSelected) => option?.identificador === valueOptionSelected?.identificador}
                                                options={PONTOS.TIPOS}
                                                value={value}
                                                onBlur={onBlur}
                                                onChange={(e, item) => {
                                                    setFieldValue(TIPO, item || null);
                                                }}
                                            />
                                        )}
                                    </Field>
                                </SttGrid>
                                <SttGrid item xs={12} sm={6} md={2}>
                                    <Field name={SITUACAO}>
                                        {({
                                            field: { name, value, onBlur },
                                            form: { setFieldValue }
                                        }) => (
                                            <SttAutocomplete
                                                inputprops={{
                                                    name: name,
                                                    label: strings.situacao
                                                }}
                                                getOptionLabel={option => option?.descricao || ''}
                                                getOptionSelected={(option, valueOptionSelected) => option?.identificador === valueOptionSelected?.identificador}
                                                options={PONTOS.SITUACAO}
                                                value={value}
                                                onBlur={onBlur}
                                                onChange={(e, item) => {
                                                    setFieldValue(SITUACAO, item || null);
                                                }}
                                            />
                                        )}
                                    </Field>
                                </SttGrid>
                                <SttGrid item xs={12} sm={6} md={3}>
                                    <Field name={REDE}>
                                        {({
                                            field: { name, value, onBlur },
                                            form: { setFieldValue }
                                        }) => (
                                            <SttAutocomplete
                                                inputprops={{
                                                    name: name,
                                                    label: strings.redeTelemedicina
                                                }}
                                                getOptionLabel={option => option?.descricao || ''}
                                                getOptionSelected={(option, valueOptionSelected) => option?.id === valueOptionSelected?.id}
                                                options={redes}
                                                value={value}
                                                onBlur={onBlur}
                                                onChange={(e, item) => {
                                                    setFieldValue(REDE, item || null);
                                                }}
                                            />
                                        )}
                                    </Field>
                                </SttGrid>
                            </SttGrid>
                            <SttGrid container spacing={1} style={{ marginTop: 10 }}>
                                <SttGrid item xs={12} className={classes.buttonWrapper}>
                                    <SttButton
                                        type="submit"
                                        variant="contained"
                                        color="primary"
                                        onClick={() => {
                                            callbackLimpar();
                                        }}
                                        disabled={isSubmitting || !isValid}
                                        nomarginleft="true"
                                    >
                                        {strings.pesquisar}
                                    </SttButton>
                                    <SttButton
                                        type="button"
                                        variant="outlined"
                                        color="primary"
                                        disabled={isSubmitting}
                                        onClick={() => {
                                            limparParametrosPesquisa();
                                            callbackLimpar();
                                            resetForm({
                                                values: estadoInicial
                                            });
                                        }}
                                    >
                                        {strings.limpar}
                                    </SttButton>
                                </SttGrid>
                            </SttGrid>
                        </form>
                    )
                }
            }
        </Formik>
    )
}

FormPesquisa.propTypes = {
    user: PropTypes.object.isRequired,
    setParametrosPesquisa: PropTypes.func.isRequired,
    limparParametrosPesquisa: PropTypes.func.isRequired,
    parametrosPesquisa: PropTypes.object.isRequired,
    callbackAndamento: PropTypes.func.isRequired,
    page: PropTypes.number.isRequired,
    count: PropTypes.number.isRequired,
    orderBy: PropTypes.string,
    order: PropTypes.string.isRequired,
    callbackBusca: PropTypes.func.isRequired,
    buscar: PropTypes.bool.isRequired
};

const mapStateToProps = (state) => {
    return {
        user: state.index.user,
        parametrosPesquisa: state.pesquisaPontosReducer.parametrosPesquisa,
    };
};

const mapDispatchToProps = dispatch => {
    return {
        setParametrosPesquisa: parametros => dispatch(setParametrosPesquisaAction(parametros)),
        limparParametrosPesquisa: () => dispatch(limparParametrosPesquisaAction()),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(translate('PesquisaPontos')(FormPesquisa));