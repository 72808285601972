import React, { useRef, useState, useEffect, memo, Suspense, lazy } from "react";
import {
    SttContainer,
    SttLoading,
    SttHeading,
    SttCircularProgress,
    SttButton
} from '@stt-componentes/core';
import axios from 'axios';
import { connect } from 'react-redux';
import HttpStatus from 'http-status-codes';
import {
    Formik
} from 'formik';
import { makeStyles } from "@material-ui/core/styles";
import translate from '@componentes/translate';
import { useNavigate } from "react-router-dom";
import validationSchema from './validationSchema';
import { getHeaders } from '@src/request';
import {
    setMotivoInvalidacaoExameAlteracao as setMotivoInvalidacaoExameAlteracaoAction
} from '@redux/actions/cadastro/invalidacao-exame/index';
import {
    setParametrosAlerta as setParametrosAlertaAction,
    setOpen as setOpenAction
} from '@redux/actions/alerta';
import UtilMotivoInvalidacaoExame from '@util/invalidacao-exame';
import { temPermissaoRBAC } from '@security/acl';
import {
    PERMISSOES
} from '@common/Constants';
const DadosMotivoInvalidacaoExame = lazy(() => import('@componentes/interno/cadastro/invalidacao-exame'));

const useStyles = makeStyles(theme => ({
    buttonWrapper: {
        marginTop: theme.spacing(1)
    },
    carregando: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center',
        width: '100%'
    },
    header: {
        marginTop: theme.spacing(2),
        marginBottom: theme.spacing(2)
    },
    botaoVoltar: {
        marginTop: theme.spacing(2)
    }
}));

const CadastroMotivosInvalidacaoExame = (params) => {
    const { strings, user, motivoInvalidacaoExameAlteracao, setParametrosAlerta, setOpen, setMotivoInvalidacaoExameAlteracao } = params;
    const schema = validationSchema(strings, user);
    const ADM_API_BASE_URL = global.gConfig.url_base_administrativo;
    const formRef = useRef();
    const classes = useStyles();
    const [initialValues, setInitialValues] = useState(null);
    const [carregado, setCarregado] = useState(false);
    const navigate = useNavigate();
    const [podeCadastrar, setPodeCadastrar] = useState(false);

    useEffect(() => {
        if (motivoInvalidacaoExameAlteracao) {
            setInitialValues(UtilMotivoInvalidacaoExame.inicializarValoresFormulario(motivoInvalidacaoExameAlteracao));
        } else {
            setInitialValues(UtilMotivoInvalidacaoExame.inicializarValoresFormulario({}));
        }

        // Trata a permissão de cadastro
        setPodeCadastrar(temPermissaoRBAC(user, PERMISSOES.CADASTRO_MOTIVOS_INVALIDACAO));

        setCarregado(true);
    }, [motivoInvalidacaoExameAlteracao]);

    const redirectPesquisaMotivosInvalidacaoExame = (resetForm) => {
        setOpen(false);

        if (motivoInvalidacaoExameAlteracao) {
            navigate("/pesquisa/invalidacao-exame", { state: { pesquisar: true } });
            setMotivoInvalidacaoExameAlteracao(null);
        } else {
            navigate("/pesquisa/invalidacao-exame");
        }

        resetForm({
            values: UtilMotivoInvalidacaoExame.inicializarValoresFormulario({})
        });
    }

    const enviarFormulario = (dados, setSubmitting, resetForm) => {
        setSubmitting(true);
        axios.post(`${ADM_API_BASE_URL}/invalidacao-exame`, dados, { headers: getHeaders() })
            .then((response) => {
                setSubmitting(false);
                const { data } = response;
                const parametrosAlerta = {
                    titulo: strings.sucesso,
                    mensagem: data.message,
                    tipo: 'success',
                    open: true,
                    opcoes: [
                        {
                            title: strings.ok,
                            onClick: () => {
                                redirectPesquisaMotivosInvalidacaoExame(resetForm);
                            }
                        }
                    ],
                    onClose: () => {
                        redirectPesquisaMotivosInvalidacaoExame(resetForm);
                    }
                };
                setParametrosAlerta(parametrosAlerta);
            })
            .catch(err => {
                setSubmitting(false);

                const { response } = err;
                let mensagem = strings.mensagemErroGeral;
                if (response) {
                    const { data } = response;

                    mensagem = data.message;
                    if (response.status === HttpStatus.BAD_REQUEST || response.status === HttpStatus.INTERNAL_SERVER_ERROR) {
                        let arrMensagem = [];
                        data.errors.forEach(error => {
                            arrMensagem.push(`- ${error.message}`);
                        });
                        if (arrMensagem.length > 0) {
                            mensagem = arrMensagem.join('\n');
                        }
                    }
                }

                const parametrosAlerta = {
                    titulo: strings.erro,
                    mensagem: mensagem,
                    tipo: 'error',
                    open: true,
                    opcoes: [
                        {
                            title: strings.ok,
                            onClick: () => {
                                setOpen(false);
                            }
                        }
                    ]
                };

                setParametrosAlerta(parametrosAlerta);
            });
    };

    return (
        <>
            {
                carregado ?
                    <Formik
                        innerRef={formRef}
                        initialValues={initialValues}
                        validationSchema={schema}
                        onSubmit={(dados, { setSubmitting, resetForm }) => {
                            let mensagem = strings.confirmacaoCadastroMotivosInvalidacaoExame;
                            if (motivoInvalidacaoExameAlteracao) {
                                mensagem = strings.confirmacaoCadastroMotivosInvalidacaoExame
                            }
                            const parametrosAlerta = {
                                titulo: strings.atencao,
                                mensagem: mensagem,
                                tipo: 'alert',
                                open: true,
                                opcoes: [
                                    {
                                        title: strings.ok,
                                        onClick: () => {
                                            let dadosFormatados = UtilMotivoInvalidacaoExame.formatarCadastro(dados);
                                            if (motivoInvalidacaoExameAlteracao) {
                                                dadosFormatados.append('alteracao', true);
                                            }
                                            enviarFormulario(dadosFormatados, setSubmitting, resetForm);
                                            setOpen(false);
                                        }
                                    },
                                    {
                                        title: strings.cancelar,
                                        onClick: () => {
                                            setOpen(false);
                                        }
                                    }
                                ]
                            };
                            setParametrosAlerta(parametrosAlerta);
                            return true;
                        }}
                        validateOnChange={false}
                    >
                        {
                            ({
                                isSubmitting,
                                handleSubmit,
                            }) => {
                                return (
                                    <SttContainer>
                                        <form onSubmit={handleSubmit} noValidate>
                                            <SttButton
                                                type="button"
                                                variant="outlined"
                                                color="primary"
                                                className={classes.botaoVoltar}
                                                onClick={() => {
                                                    const parametrosAlerta = {
                                                        titulo: strings.confirmacao,
                                                        mensagem: strings.retornarTelaPesquisa,
                                                        tipo: 'alert',
                                                        open: true,
                                                        opcoes: [
                                                            {
                                                                title: strings.sim,
                                                                onClick: () => {
                                                                    setMotivoInvalidacaoExameAlteracao(null);
                                                                    setOpen(false);
                                                                    navigate("/pesquisa/invalidacao-exame", { state: { pesquisar: true } });
                                                                }
                                                            },
                                                            {
                                                                title: strings.nao,
                                                                onClick: () => {
                                                                    setOpen(false);
                                                                }

                                                            }
                                                        ]
                                                    };
                                                    setParametrosAlerta(parametrosAlerta);
                                                }}
                                                nomarginleft="true"
                                            >
                                                {strings.voltar}
                                            </SttButton>
                                            <SttHeading variant="h1" color="primary" align="center" className={classes.header}>{strings.tituloPagina}</SttHeading>

                                            <Suspense fallback={<SttCircularProgress color="primary" />}>
                                                <DadosMotivoInvalidacaoExame />
                                            </Suspense>

                                            {
                                                podeCadastrar &&
                                                <div className={classes.buttonWrapper}>
                                                    <SttButton
                                                        nomarginleft="true"
                                                        type="submit"
                                                        variant="contained"
                                                        color="primary"
                                                        disabled={isSubmitting}
                                                    >
                                                        {strings.salvar}
                                                    </SttButton>
                                                </div>
                                            }
                                        </form>
                                        <SttLoading
                                            open={isSubmitting}
                                            text={strings.salvando}
                                        />

                                    </SttContainer>
                                )
                            }
                        }
                    </Formik> :
                    (
                        <div className={classes.carregando}>
                            <SttCircularProgress color="primary" />
                        </div>
                    )
            }
        </>
    );
};

const mapStateToProps = (state) => {
    return {
        user: state.index.user,
        motivoInvalidacaoExameAlteracao: state.cadastroMotivoInvalidacaoExameReducer.motivoInvalidacaoExameAlteracao
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        setMotivoInvalidacaoExameAlteracao: id => dispatch(setMotivoInvalidacaoExameAlteracaoAction(id)),
        setParametrosAlerta: (parametros) => dispatch(setParametrosAlertaAction(parametros)),
        setOpen: (open) => dispatch(setOpenAction(open))
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(translate('CadastroMotivosInvalidacaoExame')(CadastroMotivosInvalidacaoExame));
