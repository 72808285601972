export const CNES = 'cnes';
export const INSTITUICAO = 'instituicao';
export const UF = 'uf';
export const CIDADE = 'cidade';
export const SETOR = 'setor';
export const NOME = 'nome';
export const TIPO = 'tipo';
export const SITUACAO = 'situacao';
export const REDE = 'rede';
export const DATA_DE = 'data-de';
export const DATA_ATE = 'data-ate';